import {
  getOrCreateDefaultCustomAccount,
  CustomAccount,
  getAccount,
  getOrCreateDefaultCustomDepositoryAccount,
} from './importDummyDataHelpers'
import { ImportInvoices } from './ImportInvoices'
import { ImportPayouts } from './ImportPayouts'
import { ImportTransfers } from './ImportTransfers'
import { ImportBankTransactions } from './ImportBankTransactions'
import { ImportPayrollManualEntries } from './ImportPayrollManualEntries'
import { ImportBills } from './ImportBills'
import React, {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Loading } from '../Loading'
import { LAYER_API_URL } from '../../Root'

export const textAreaStyle: CSSProperties = {
  width: '100%',
  height: '200px',
  resize: 'none',
  overflowY: 'auto',
}

interface ImportDummyDataProps {
  demoName: string
  businessId: string
  bearerToken: string
  setImportingTransactions: Dispatch<SetStateAction<boolean>>
}

export const ImportDummyData = ({
  demoName,
  businessId,
  bearerToken,
  setImportingTransactions,
}: ImportDummyDataProps) => {
  const [loading, setLoading] = useState(true)
  const [defaultCustomAccount, setDefaultCustomAccount] =
    useState<CustomAccount | null>(null)
  const [customLedgerAccount, setCustomLedgerAccount] = useState<string | null>(
    null,
  )
  const [defaultCustomDepositoryAccount, setDefaultCustomDepositoryAccount] =
    useState<CustomAccount | null>(null)

  useEffect(() => {
    const initializeAccounts = async () => {
      try {
        const [creditCardAccount, depositoryAccount] = await Promise.all([
          getOrCreateDefaultCustomAccount(businessId, bearerToken),
          getOrCreateDefaultCustomDepositoryAccount(businessId, bearerToken),
        ])

        setDefaultCustomAccount(creditCardAccount)
        setDefaultCustomDepositoryAccount(depositoryAccount)

        const defaultCustomDepositoryAccountAsExternalAccount =
          await getAccount(businessId, bearerToken, depositoryAccount.id)
        setCustomLedgerAccount(
          defaultCustomDepositoryAccountAsExternalAccount.ledger_account_id,
        )

        setLoading(false)
      } catch (error) {
        console.error('Error initializing accounts:', error)
        setLoading(false)
      }
    }

    initializeAccounts()
  }, [businessId, bearerToken])

  const OtherActions = () => {
    const ApproveMatchesButton = () => {
      const handleSubmit = async () => {
        const today = new Date()
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1,
        )
        const sevenDaysAgo = new Date(today)
        sevenDaysAgo.setDate(today.getDate() - 7)
        const categorizationCutoffDate = (
          firstDayOfMonth < sevenDaysAgo ? firstDayOfMonth : sevenDaysAgo
        ).toISOString()

        try {
          const txnsLayerReviewResponse = await fetch(
            `${LAYER_API_URL}/v1/businesses/${businessId}/bank-transactions?categorization_status=LAYER_REVIEW&end_date=${categorizationCutoffDate}&limit=500`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
              },
            },
          )
          const txnsLayerReviewData = await txnsLayerReviewResponse.json()
          const txnsReadyForInputResponse = await fetch(
            `${LAYER_API_URL}/v1/businesses/${businessId}/bank-transactions?categorization_status=READY_FOR_INPUT&end_date=${categorizationCutoffDate}&limit=500`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
              },
            },
          )
          const txnsReadyForInputData = await txnsReadyForInputResponse.json()

          const transactionLayerReviewMatchPairs = txnsLayerReviewData.data
            .filter((txn: any) => (txn.suggested_matches as any[]).length > 0)
            .map((txn: any) => ({
              transaction_id: txn.id,
              suggested_match_id: txn.suggested_matches[0].id,
            }))

          const transactionReadyForInputMatchPairs = txnsReadyForInputData.data
            .filter((txn: any) => (txn.suggested_matches as any[]).length > 0)
            .map((txn: any) => ({
              transaction_id: txn.id,
              suggested_match_id: txn.suggested_matches[0].id,
            }))

          const bulkMatchLayerReviewResponse = await fetch(
            `${LAYER_API_URL}/v1/businesses/${businessId}/bank-transactions/bulk-match`,
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                match_pairs: transactionLayerReviewMatchPairs,
              }),
            },
          )

          const bulkMatchReadyForInputResponse = await fetch(
            `${LAYER_API_URL}/v1/businesses/${businessId}/bank-transactions/bulk-match`,
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                match_pairs: transactionReadyForInputMatchPairs,
              }),
            },
          )
          bulkMatchLayerReviewResponse.json()
          bulkMatchReadyForInputResponse.json()

          alert('Transaction matches matched approved successfully!')
        } catch (error) {
          console.error('Error approving transactions:', error)
          alert(`Failed to approve transactions: ${error}`)
        }
      }

      return (
        <button className='admin-button' onClick={handleSubmit}>
          Approve all matched transactions
        </button>
      )
    }

    const SyncBusinessButton = () => {
      const handleSubmit = () => {
        try {
          const syncResponse = fetch(
            `${LAYER_API_URL}/v1/businesses/${businessId}/sync?synchronous=true`,
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
              },
            },
          )
          alert(
            'Business sync initiated. The initial backfill will take ~90 seconds.',
          )
        } catch (error) {
          console.error('Error syncing business:', error)
          alert(`Failed to sync business: ${error}`)
        }
      }
      return (
        <button className='admin-button' onClick={handleSubmit}>
          Sync Business
        </button>
      )
    }

    return (
      <fieldset
        style={{
          padding: '20px',
          border: '2px solid black',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
          marginTop: '20px',
        }}
      >
        <legend style={{ fontSize: '1.2em', padding: '0 10px' }}>
          Other Actions
        </legend>
        <ApproveMatchesButton />
        <SyncBusinessButton />
      </fieldset>
    )
  }

  if (loading) return <Loading />

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100vh',
        width: '100%',
        position: 'relative',
      }}
    >
      <h1>Import Transaction Data</h1>
      {defaultCustomAccount == null ||
      customLedgerAccount == null ||
      defaultCustomDepositoryAccount == null ? (
        <>
          No Custom Account found for business {businessId}
          <button
            onClick={async () => {
              const [creditCardAccount, depositoryAccount] = await Promise.all([
                getOrCreateDefaultCustomAccount(businessId, bearerToken),
                getOrCreateDefaultCustomDepositoryAccount(
                  businessId,
                  bearerToken,
                ),
              ])

              setDefaultCustomAccount(creditCardAccount)
              setDefaultCustomDepositoryAccount(depositoryAccount)

              const defaultCustomAccountAsExternalAccount = await getAccount(
                businessId,
                bearerToken,
                creditCardAccount.id,
              )
              setCustomLedgerAccount(
                defaultCustomAccountAsExternalAccount.ledger_account_id,
              )
            }}
          >
            Get or create default accounts
          </button>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            gap: '20px',
            maxWidth: '90%',
          }}
        >
          <ImportInvoices
            demoName={demoName}
            businessId={businessId}
            bearerToken={bearerToken}
            defaultCustomAccount={defaultCustomDepositoryAccount}
          />
          <ImportPayouts
            demoName={demoName}
            businessId={businessId}
            bearerToken={bearerToken}
            defaultCustomAccount={defaultCustomDepositoryAccount}
          />
          <ImportBills
            demoName={demoName}
            businessId={businessId}
            bearerToken={bearerToken}
            defaultCustomAccount={defaultCustomAccount}
          />
          <ImportBankTransactions
            demoName={demoName}
            businessId={businessId}
            bearerToken={bearerToken}
            defaultCustomAccount={defaultCustomAccount}
          />
          <ImportPayrollManualEntries
            demoName={demoName}
            businessId={businessId}
            bearerToken={bearerToken}
            defaultCustomAccount={defaultCustomDepositoryAccount}
            customAccountLedgerAccountId={customLedgerAccount}
          />
          {defaultCustomDepositoryAccount && (
            <ImportTransfers
              demoName={demoName}
              businessId={businessId}
              bearerToken={bearerToken}
              defaultCustomAccount={defaultCustomAccount}
              defaultCustomDepositoryAccount={defaultCustomDepositoryAccount}
            />
          )}
        </div>
      )}
      <br />
      <OtherActions />
      <button
        className='admin-button'
        style={{
          width: '120px',
          position: 'absolute',
          bottom: '20px',
          left: '20px',
        }}
        onClick={() => setImportingTransactions(false)}
      >
        Return to demo
      </button>
    </div>
  )
}
