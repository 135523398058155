import {
  getInvoicesForPayouts,
  validatePayoutParamsList,
  getPayouts,
  createMatchingCustomTransactionsFromPayouts,
  createCustomTransactions,
  CustomAccount,
} from './importDummyDataHelpers'
import { ImportForm } from './ImportForm'
import React from 'react'
import { LAYER_API_URL } from '../../Root'

export interface ImportPayoutsProps {
  demoName: string
  businessId: string
  bearerToken: string
  defaultCustomAccount: CustomAccount
}

export const ImportPayouts = ({
  demoName,
  businessId,
  bearerToken,
  defaultCustomAccount,
}: ImportPayoutsProps) => {
  return (
    <ImportForm
      title='Payouts'
      defaultCustomAccount={defaultCustomAccount}
      getInitialData={() => {
        // Generate invoices once and store result
        const invoices = getInvoicesForPayouts(demoName)
        // Generate payouts based on these exact invoices
        return {
          invoices,
          payouts: getPayouts(demoName, invoices),
        }
      }}
      validateData={data => {
        // Validate just the payouts portion of the data
        return validatePayoutParamsList(data.payouts)
      }}
      onSubmit={async data => {
        // First create the invoices
        const invoiceResponse = await fetch(
          `${LAYER_API_URL}/v1/businesses/${businessId}/invoices/bulk`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${bearerToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data.invoices),
          },
        )
        if (!invoiceResponse.ok) {
          throw new Error('Invoice creation failed')
        }
        await invoiceResponse.json() // Consume the response

        // Then create payouts one by one
        for (const payout of data.payouts) {
          const payoutResponse = await fetch(
            `${LAYER_API_URL}/v1/businesses/${businessId}/payouts`,
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(payout),
            },
          )
          if (!payoutResponse.ok) {
            throw new Error(
              `Payout creation failed for payout ${payout.external_id}: ${payoutResponse.statusText}`,
            )
          }
          await payoutResponse.json() // Consume the response
          await new Promise(resolve => setTimeout(resolve, 1000))
        }

        // Create matching transactions
        const matchingTxns = createMatchingCustomTransactionsFromPayouts(
          data.payouts,
          demoName,
        )
        await createCustomTransactions(
          businessId,
          bearerToken,
          defaultCustomAccount.id,
          matchingTxns,
        )
      }}
      dependencies={[demoName]}
    />
  )
}
