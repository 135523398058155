import React from 'react'
import { Reports as ReportsView } from '@layerfi/components'
import { AccountingLayout } from '../../../layouts/AccountingLayout'
import { useOutletContext } from 'react-router-dom'
import { DateRangeDatePickerModes } from '../../Admin'
import { ProfitAndLossCompareOptionsProps } from '@layerfi/components/components/ProfitAndLossCompareOptions/ProfitAndLossCompareOptions'

export const Reports = () => {
  const { datePickerMode, useComparisonPnl, demoName } = useOutletContext<{
    datePickerMode?: DateRangeDatePickerModes
    useComparisonPnl: boolean
    demoName: string
  }>()

  const nyTagFilter = {
    displayName: 'New York',
    tagFilterConfig: {
      tagFilters: {
        structure: 'DEFAULT',
        tagKey: 'location',
        tagValues: ['new york'],
      },
    },
  }

  const sfTagFilter = {
    displayName: 'San Francisco',
    tagFilterConfig: {
      tagFilters: {
        structure: 'DEFAULT',
        tagKey: 'location',
        tagValues: ['san francisco'],
      },
    },
  }

  const laTagFilter = {
    displayName: 'Los Angeles',
    tagFilterConfig: {
      tagFilters: {
        structure: 'DEFAULT',
        tagKey: 'location',
        tagValues: ['los angeles'],
      },
    },
  }

  const allLocationsTagFilter = {
    displayName: 'All Locations',
    tagFilterConfig: {
      structure: 'DEFAULT',
      tagFilters: {
        tagKey: 'location',
        tagValues: [],
      },
    },
  }

  const tagComparisonConfig = {
    tagComparisonOptions: [
      nyTagFilter,
      sfTagFilter,
      laTagFilter,
      allLocationsTagFilter,
    ],
    defaultTagFilter: allLocationsTagFilter,
  }

  const prop1TagFilter = {
    displayName: '524 Clarendon LLC',
    tagFilterConfig: {
      tagFilters: {
        structure: 'DEFAULT',
        tagKey: 'property',
        tagValues: ['524 clarendon llc'],
      },
    },
  }

  const prop2TagFilter = {
    displayName: 'Bay Bridge Investments LLC',
    tagFilterConfig: {
      tagFilters: {
        structure: 'DEFAULT',
        tagKey: 'property',
        tagValues: ['bay bridge investments, llc'],
      },
    },
  }

  const allPropertiesTagFilter = {
    displayName: 'All Properties',
    tagFilterConfig: {
      structure: 'DEFAULT',
      tagFilters: {
        tagKey: 'property',
        tagValues: [],
      },
    },
  }

  const releaseTagComparisonConfig = {
    tagComparisonOptions: [
      prop1TagFilter,
      prop2TagFilter,
      allPropertiesTagFilter,
    ],
    defaultTagFilter: allPropertiesTagFilter,
  }

  const comparisonConfig: ProfitAndLossCompareOptionsProps | undefined =
    useComparisonPnl || demoName == 'Impersonate'
      ? demoName == 'Release'
        ? releaseTagComparisonConfig
        : tagComparisonConfig
      : undefined

  return (
    <AccountingLayout>
      <ReportsView
        profitAndLossConfig={{
          datePickerMode: datePickerMode ?? undefined,
        }}
        comparisonConfig={comparisonConfig}
      />
    </AccountingLayout>
  )
}
